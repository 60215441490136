import type {
  EditorJsonContent,
  FalconContent,
  LogoShape,
  TilesContent,
  VideoSrc,
} from '../contentTypes';
import { emptyImage, emptyLogo, emptyVideo } from './testContent';
import { tileModelToNemo } from './tileModelToNemo';

export const mapHeadlessContent = (content: FalconContent): TilesContent => {
  const { layout_name: name, layout_size: size, tiles } = content.fields;
  const appContent: TilesContent = {
    layout: {
      name,
      size,
    },
    tiles: tiles.map((tile) => tileModelToNemo(tile)),
  };
  return appContent;
};

export const mapFaceContent = (content: EditorJsonContent): TilesContent => {
  const { layout, tiles } = content;
  const newTiles = tiles.map((tile) => {
    const { media, logo, ...restTile } = tile;
    const { image, imageEnabled, videoEnabled, video, ...restMedia } = media;
    const { newImageEnabled, newVideoEnabled } = (() => {
      if (
        (typeof imageEnabled === 'undefined' && typeof videoEnabled === 'undefined') ||
        !(imageEnabled || videoEnabled)
      ) {
        return {
          newImageEnabled: true,
          newVideoEnabled: false,
        };
      }
      return {
        newImageEnabled: imageEnabled,
        newVideoEnabled: videoEnabled,
      };
    })();
    const newLogo = (() => {
      if (logo) {
        const { src: logoSrc, srcAsset: logoSrcAsset, ...restLogo } = logo;
        return {
          src: (typeof logoSrcAsset === 'object' && logoSrcAsset.assetValue) || logoSrc || '',
          srcAsset: { assetValue: '' },
          ...restLogo,
        } as LogoShape;
      }
      return emptyLogo;
    })();
    const newImage = (() => {
      if (image) {
        const { srcSet, ...restImage } = image;
        const newSrcSet = srcSet.map((srcItem) => {
          const newSrc =
            typeof srcItem.srcAsset === 'object' && srcItem.srcAsset?.assetValue
              ? srcItem.srcAsset?.assetValue
              : srcItem.src;
          return { bp: srcItem.bp, src: newSrc, srcAsset: '' };
        });
        return {
          srcSet: newSrcSet,
          ...restImage,
        };
      }
      return emptyImage;
    })();
    const newVideo = (() => {
      if (video) {
        const { srcs, ...restVideo } = video;
        const newVideoSrc = Object.entries(srcs).reduce((acc, [key, value]) => {
          const assetSrc = typeof value?.srcAsset === 'object' && value?.srcAsset?.assetValue;
          const newSrc = assetSrc || value?.src;
          const assetPoster =
            typeof value?.posterAsset === 'object' && value?.posterAsset?.assetValue;
          const newPoster = assetPoster || value?.poster;
          return {
            ...acc,
            [key]: {
              src: newSrc,
              srcAsset: '',
              poster: newPoster,
              posterAsset: '',
            },
          };
        }, {}) as VideoSrc;
        return {
          srcs: newVideoSrc,
          ...restVideo,
        };
      }
      return emptyVideo;
    })();
    return {
      logo: newLogo,
      media: {
        image: newImage,
        video: newVideo,
        imageEnabled: newImageEnabled,
        videoEnabled: newVideoEnabled,
        ...restMedia,
      },
      ...restTile,
    };
  });

  return { layout, tiles: newTiles };
};
