/* eslint-disable camelcase */
// autogenerated names don't follow camelCase
import type { Tile, TileFragment } from '../contentTypes';

export const tileModelToNemo = (tile: TileFragment): Tile => {
  const {
    theme,
    icon,
    linkType,
    media_newWindow,
    media_fadeEnabled,
    media_zoomEnabled,
    media_fade_color,
    media_fade_opacity,
    media_fade_height,
    media_fade_direction,
    media_imageEnabled,
    media_videoEnabled,
    media_image_alt,

    media_video_autoplay,
    media_video_loop,
    media_video_srcs_xs_src,
    media_video_srcs_xs_srcAsset,
    media_video_srcs_xs_poster,
    media_video_srcs_xs_posterAsset,
    media_video_srcs_s_src,
    media_video_srcs_s_srcAsset,
    media_video_srcs_s_poster,
    media_video_srcs_s_posterAsset,
    media_video_srcs_m_src,
    media_video_srcs_m_srcAsset,
    media_video_srcs_m_poster,
    media_video_srcs_m_posterAsset,
    media_video_srcs_l_src,
    media_video_srcs_l_srcAsset,
    media_video_srcs_l_poster,
    media_video_srcs_l_posterAsset,
    media_video_srcs_xl_src,
    media_video_srcs_xl_srcAsset,
    media_video_srcs_xl_poster,
    media_video_srcs_xl_posterAsset,
    media_video_srcs_xxl_src,
    media_video_srcs_xxl_srcAsset,
    media_video_srcs_xxl_poster,
    media_video_srcs_xxl_posterAsset,

    logo_alt,
    logo_src,
    logo_srcAsset,
    logo_heights_xs,
    logo_heights_s,
    logo_heights_m,
    logo_heights_l,
    logo_heights_xl,
    logo_heights_xxl,

    heading_text,
    heading_variant,
    heading_htmlTag,
    heading_embolden,

    subHeading_text,
    subHeading_variant,
    subHeading_htmlTag,
    subHeading_embolden,

    CTA_href,
    CTA_variant,
    CTA_text,
    CTA_newWindow,

    horizontalAlignment,
    horizontalAlignmentSmall,

    verticalAlignment,
    verticalAlignmentSmall,

    materialDisclaimer_xs_disclaimer,
    materialDisclaimer_xs_theme,
    materialDisclaimer_xs_position,

    materialDisclaimer_s_disclaimer,
    materialDisclaimer_s_theme,
    materialDisclaimer_s_position,

    materialDisclaimer_m_disclaimer,
    materialDisclaimer_m_theme,
    materialDisclaimer_m_position,

    materialDisclaimer_l_disclaimer,
    materialDisclaimer_l_theme,
    materialDisclaimer_l_position,

    materialDisclaimer_xl_disclaimer,
    materialDisclaimer_xl_theme,
    materialDisclaimer_xl_position,

    materialDisclaimer_xxl_disclaimer,
    materialDisclaimer_xxl_theme,
    materialDisclaimer_xxl_position,

    media_image_srcSet,
  } = tile.fields;

  return {
    CTA: {
      href: CTA_href || '',
      variant: CTA_variant || 'secondary',
      text: CTA_text || '',
      newWindow: CTA_newWindow,
    },
    heading: {
      text: heading_text || '',
      variant: heading_variant,
      htmlTag: heading_htmlTag || 'h1',
      embolden: heading_embolden,
    },
    horizontalAlignment,
    horizontalAlignmentSmall,
    icon: icon || '',
    linkType,
    logo: {
      alt: logo_alt || '',
      src: logo_srcAsset?.path || logo_src || '',
      heights: {
        xs: logo_heights_xs || '',
        s: logo_heights_s || '',
        m: logo_heights_m || '',
        l: logo_heights_l || '',
        xl: logo_heights_xl || '',
        xxl: logo_heights_xxl || '',
      },
    },
    materialDisclaimer: {
      xs: {
        disclaimer: materialDisclaimer_xs_disclaimer || '',
        position: materialDisclaimer_xs_position || 'bottomRight',
        theme: materialDisclaimer_xs_theme || 'dark',
      },
      s: {
        disclaimer: materialDisclaimer_s_disclaimer || '',
        position: materialDisclaimer_s_position || 'bottomRight',
        theme: materialDisclaimer_s_theme || 'dark',
      },
      m: {
        disclaimer: materialDisclaimer_m_disclaimer || '',
        position: materialDisclaimer_m_position || 'bottomRight',
        theme: materialDisclaimer_m_theme || 'dark',
      },
      l: {
        disclaimer: materialDisclaimer_l_disclaimer || '',
        position: materialDisclaimer_l_position || 'bottomRight',
        theme: materialDisclaimer_l_theme || 'dark',
      },
      xl: {
        disclaimer: materialDisclaimer_xl_disclaimer || '',
        position: materialDisclaimer_xl_position || 'bottomRight',
        theme: materialDisclaimer_xl_theme || 'dark',
      },
      xxl: {
        disclaimer: materialDisclaimer_xxl_disclaimer || '',
        position: materialDisclaimer_xxl_position || 'bottomRight',
        theme: materialDisclaimer_xxl_theme || 'dark',
      },
    },
    media: {
      newWindow: media_newWindow,
      fadeEnabled: media_fadeEnabled,
      fade: {
        color: media_fade_color || '',
        opacity: media_fade_opacity || '',
        height: media_fade_height || '',
        direction: media_fade_direction || 'bottom',
      },
      imageEnabled: media_imageEnabled,
      videoEnabled: media_videoEnabled,
      zoomEnabled: media_zoomEnabled,
      image: {
        alt: media_image_alt || '',
        srcSet: media_image_srcSet.map((srcSet) => {
          const src =
            (typeof srcSet.fields?.srcAsset === 'object' && srcSet.fields?.srcAsset?.path) ||
            srcSet.fields?.src ||
            '';
          return {
            ...srcSet.fields,
            src,
            srcAsset: '',
          };
        }),
      },
      video: {
        autoplay: media_video_autoplay,
        loop: media_video_loop,
        srcs: {
          xs: {
            src: media_video_srcs_xs_srcAsset?.path || media_video_srcs_xs_src || '',
            poster: media_video_srcs_xs_posterAsset?.path || media_video_srcs_xs_poster || '',
          },
          s: {
            src: media_video_srcs_s_srcAsset?.path || media_video_srcs_s_src || '',
            poster: media_video_srcs_s_posterAsset?.path || media_video_srcs_s_poster || '',
          },
          m: {
            src: media_video_srcs_m_srcAsset?.path || media_video_srcs_m_src || '',
            poster: media_video_srcs_m_posterAsset?.path || media_video_srcs_m_poster || '',
          },
          l: {
            src: media_video_srcs_l_srcAsset?.path || media_video_srcs_l_src || '',
            poster: media_video_srcs_l_posterAsset?.path || media_video_srcs_l_poster || '',
          },
          xl: {
            src: media_video_srcs_xl_srcAsset?.path || media_video_srcs_xl_src || '',
            poster: media_video_srcs_xl_posterAsset?.path || media_video_srcs_xl_poster || '',
          },
          xxl: {
            src: media_video_srcs_xxl_srcAsset?.path || media_video_srcs_xxl_src || '',
            poster: media_video_srcs_xxl_posterAsset?.path || media_video_srcs_xxl_poster || '',
          },
        },
      },
    },
    subHeading: {
      text: subHeading_text || '',
      variant: subHeading_variant,
      htmlTag: subHeading_htmlTag,
      embolden: subHeading_embolden,
    },
    theme,
    verticalAlignment,
    verticalAlignmentSmall,
  };
};
