/* eslint-disable security/detect-object-injection */
// data validated in AEM
import { bool, number, shape, string } from 'prop-types';
import React from 'react';

import { StyledContentTileContainer } from '../ContentTiles/styles';
import { TileSizes } from '../ContentTiles/tileSizes';
import { handleSetTileHeights, setMobileTileHeights, setTileHeights } from '../ContentTiles/utils';

import { ContentTile } from '.';
import {
  getClickEvent,
  getClickEventAction,
  getSanitizedUrl,
} from '../../../tracking/getClickEvent';
import { useTrackingService } from '../../hooks/useTrackingService';

export function ContentTileContainer({
  index,
  tileData,
  setSize,
  setTileHeightsDirectly,
  leadCardIndex,
  layoutName,
  layoutSize,
  width,
}) {
  const { trackingService } = useTrackingService();
  const onTileClick = (e) => {
    if (trackingService) {
      const sanitizedUrl = getSanitizedUrl(trackingService, tileData);
      const eventAction = getClickEventAction(trackingService, sanitizedUrl);
      const event = getClickEvent(eventAction, sanitizedUrl, tileData, index);

      trackingService.track({ event });
      e.stopPropagation();
    }
  };
  return (
    <StyledContentTileContainer width={width} layout={layoutName}>
      <ContentTile
        // eslint-disable-next-line
        key={index}
        index={index}
        layoutName={layoutName}
        {...tileData}
        size={
          setSize && !setTileHeightsDirectly
            ? handleSetTileHeights(index, layoutName, layoutSize)
            : setSize && setTileHeightsDirectly
              ? setTileHeights(TileSizes[layoutName].sizeTwo[layoutSize])
              : null
        }
        mobileBreakpointSizes={{
          s: setMobileTileHeights(index, leadCardIndex, '375 breakpoint', layoutSize, layoutName),
          m: setMobileTileHeights(index, leadCardIndex, '768 breakpoint', layoutSize, layoutName),
        }}
        onClickEvent={onTileClick}
      />
    </StyledContentTileContainer>
  );
}

ContentTileContainer.displayName = 'ContentTileContainer';

ContentTileContainer.propTypes = {
  index: number.isRequired,
  tileData: shape(ContentTile.propTypes),
  setSize: bool.isRequired,
  setTileHeightsDirectly: bool.isRequired,
  leadCardIndex: number.isRequired,
  layoutName: string.isRequired,
  layoutSize: string.isRequired,
  width: string,
};
