import React from "react";
import reactStringReplace from "react-string-replace";
import { FootnoteReference, FootnoteReferenceV2 } from "./FootnoteReference";

export * from "./FootnoteReference";
export * from "./use-footnote-service";
export * from "./transformTrackingText";

function replaceTextWithCallbackResult(
  text: string,
  cb: (match: string, i: number) => JSX.Element
) {
  let textWithReplacedFootnotes;

  textWithReplacedFootnotes = reactStringReplace(
    text,
    /{ft_([a-zA-Z0-9_-]+)}/g,
    cb
  );

  textWithReplacedFootnotes = reactStringReplace(
    textWithReplacedFootnotes,
    /{{footnote.reference.([a-zA-Z0-9_-]+)}}/g,
    cb
  );

  return textWithReplacedFootnotes;
}

/**
 * Takes a text containing footnote placeholders with the format `{ft_some_footnote_id}` and replaces them with
 * `FootnoteReference` components which contain a link to navigate to the corresponding footnote text.
 *
 * @param text text containing footnote placeholders
 *
 * @example
 * renderTextWithFootnotesReferences(
 * 'Stromverbrauch kombiniert<sup>{ft_nemo-fn_eec-global-disclaimer}</sup>: 23,6 / 100 km'
 * )
 */
export function renderTextWithFootnotesReferences(
  text: string
): React.ReactNodeArray {
  const replaceCb = (match: string, i: number) => (
    <FootnoteReference key={i} footnoteId={match} />
  );
  return replaceTextWithCallbackResult(text, replaceCb);
}

/**
 * Takes a text containing footnote placeholders with the format `{ft_some_footnote_id}` and replaces them with
 * `FootnoteReferenceV2` components, which contain a link to navigate to the corresponding footnote text.
 *
 * @param text - The text containing footnote placeholders.
 * @param ariaText - (optional) ARIA label text to improve accessibility of the footnote references.
 *
 * @returns An array of React nodes with `FootnoteReferenceV2` components replacing the placeholders.
 *
 * @example
 * renderTextWithFootnotesReferencesV2(
 * 'Stromverbrauch kombiniert<sup>{ft_nemo-fn_eec-global-disclaimer}</sup>: 23,6 / 100 km'
 * )
 */
export function renderTextWithFootnotesReferencesV2(
  text: string,
  ariaText?: string
): React.ReactNode[] {
  // Callback function to replace footnote placeholders with FootnoteReferenceV2 components
  const replaceCb = (match: string, i: number) => (
    <FootnoteReferenceV2
      key={i}
      footnoteId={match}
      footnoteAriaLabel={ariaText}
    />
  );

  // Replace footnote placeholders with FootnoteReferenceV2 components
  const footnoteNodes = replaceTextWithCallbackResult(text, replaceCb);

  // Insert commas between footnotes if needed
  const formattedFootnoteNodes = insertSupElements(footnoteNodes);

  return formattedFootnoteNodes;
}

/**
 * Processes an array of React nodes to insert `<sup>,</sup>` elements between consecutive
 * `FootnoteReferenceV2` components. This ensures that footnotes are properly separated
 * with commas when rendered.
 *
 * @param nodes - An array of React nodes, potentially containing `FootnoteReferenceV2` components.
 *
 * @returns A new array of React nodes with `<sup>,</sup>` elements inserted between
 * consecutive `FootnoteReferenceV2` components.
 *
 * @example
 * const nodes = [
 *   <FootnoteReferenceV2 key="1" footnoteId="ft1" />,
 *   <FootnoteReferenceV2 key="2" footnoteId="ft2" />,
 *   " Some text ",
 *   <FootnoteReferenceV2 key="3" footnoteId="ft3" />,
 * ];
 */
function insertSupElements(nodes: React.ReactNode[]): React.ReactNode[] {
  const processedNodes: React.ReactNode[] = [];
  let previousWasFootnote = false;

  nodes.forEach((node) => {
    const isFootnote =
      React.isValidElement(node) && node.type === FootnoteReferenceV2;

    const isWhitespaceString = (n: React.ReactNode) =>
      typeof n === "string" && n.trim() === "";

    // Insert comma if the previous node was a footnote and this one is too
    if (isFootnote && previousWasFootnote) {
      processedNodes.push(<sup translate="no">, </sup>);
    }

    processedNodes.push(node);

    // Update previousWasFootnote status, ignoring whitespace-only strings
    if (!isWhitespaceString(node)) {
      previousWasFootnote = isFootnote;
    }
  });

  return processedNodes;
}
