import { withResponsiveDetails } from '@oneaudi/aoa-shared-editorial-components';
import { arrayOf, oneOf, shape } from 'prop-types';
import React, { useCallback } from 'react';
import uuid from 'react-uuid';

import { getImpresionEvent } from '../../../tracking/impressionEvent';
import { useTrackingService } from '../../hooks/useTrackingService';
import { ContentTileContainer } from '../ContentTile/ContentTileContainer';
import { CT_LAYOUT, CT_SIZE } from './constants';
import { Container, SplitColumnContainer } from './styles';
import { props as contentTileDefaults } from './testData';
import { setTileWidths } from './utils';
import { withFocusReset } from './withFocusReset';

// exported for testing purposes
export function _ContentTiles({
  tiles,
  layout: { name: layoutName, size: layoutSize },
  currentBreakpoint,
}) {
  const { trackingService } = useTrackingService();

  const componentRef = useCallback(
    (node) => {
      if (node !== null && trackingService) {
        trackingService.registerImpressionTracking(node, () => {
          return {
            event: getImpresionEvent({ tiles, layout: { name: layoutName, size: layoutSize } }),
          };
        });
      }
    },
    [trackingService],
  );

  return (
    <Container data-test-id="content-tiles" layout={layoutName} ref={componentRef}>
      {layoutName === CT_LAYOUT.IRREGULAR_2X1_SPLIT_COLUMN_RIGHT ? (
        <>
          {(currentBreakpoint === 'l' ||
            currentBreakpoint === 'xl' ||
            currentBreakpoint === 'xxl') &&
            tiles[2] && (
              <ContentTileContainer
                index={2}
                tileData={tiles[2]}
                setSize
                setTileHeightsDirectly={false}
                leadCardIndex={2}
                layoutName={layoutName}
                layoutSize={layoutSize}
                width={setTileWidths(2, layoutName)}
                key={uuid()}
              />
            )}
          {(currentBreakpoint === 'xs' || currentBreakpoint === 's' || currentBreakpoint === 'm') &&
            tiles[2] && (
              <ContentTileContainer
                index={2}
                tileData={tiles[2]}
                setSize={false}
                setTileHeightsDirectly={false}
                leadCardIndex={2}
                layoutName={layoutName}
                layoutSize={layoutSize}
                width={setTileWidths(2, layoutName)}
              />
            )}
          <SplitColumnContainer>
            {tiles.map((tile, index) => {
              if (index === 2) return false;
              return (
                <ContentTileContainer
                  key={uuid()}
                  index={index}
                  tileData={tile}
                  setSize
                  setTileHeightsDirectly
                  leadCardIndex={2}
                  layoutName={layoutName}
                  layoutSize={layoutSize}
                  width={setTileWidths(index, layoutName)}
                />
              );
            })}
          </SplitColumnContainer>
        </>
      ) : layoutName === CT_LAYOUT.IRREGULAR_2X1_SPLIT_COLUMN_LEFT ? (
        <>
          {(currentBreakpoint === 'xs' || currentBreakpoint === 's' || currentBreakpoint === 'm') &&
            tiles[2] && (
              <ContentTileContainer
                index={2}
                tileData={tiles[2]}
                setSize={false}
                setTileHeightsDirectly={false}
                leadCardIndex={2}
                layoutName={layoutName}
                layoutSize={layoutSize}
                width={setTileWidths(2, layoutName)}
              />
            )}
          <SplitColumnContainer>
            {tiles.map((tile, index) => {
              if (index === 2) return false;

              return (
                <ContentTileContainer
                  index={index}
                  tileData={tile}
                  setSize
                  setTileHeightsDirectly
                  leadCardIndex={2}
                  layoutName={layoutName}
                  layoutSize={layoutSize}
                  width={setTileWidths(index, layoutName)}
                  key={uuid()}
                />
              );
            })}
          </SplitColumnContainer>
          {(currentBreakpoint === 'l' ||
            currentBreakpoint === 'xl' ||
            currentBreakpoint === 'xxl') &&
            tiles[2] && (
              <ContentTileContainer
                index={2}
                tileData={tiles[2]}
                setSize
                setTileHeightsDirectly={false}
                leadCardIndex={2}
                layoutName={layoutName}
                layoutSize={layoutSize}
                width={setTileWidths(2, layoutName)}
                key={uuid()}
              />
            )}
        </>
      ) : (
        tiles.map((tile, index) => (
          <ContentTileContainer
            index={index}
            tileData={tile}
            setSize
            setTileHeightsDirectly={false}
            leadCardIndex={0}
            layoutName={layoutName}
            layoutSize={layoutSize}
            width={setTileWidths(index, layoutName)}
            key={uuid()}
          />
        ))
      )}
    </Container>
  );
}

_ContentTiles.displayName = 'ContentTiles';

_ContentTiles.propTypes = {
  tiles: arrayOf(shape(ContentTileContainer.propTypes.tileData)),
  layout: shape({
    name: oneOf([...Object.values(CT_LAYOUT)]),
    size: oneOf([...Object.values(CT_SIZE)]),
  }),
};

_ContentTiles.defaultProps = contentTileDefaults;

export const ContentTiles = withFocusReset(withResponsiveDetails(_ContentTiles));
