import React from "react";
import styled from "styled-components";

import { useFootnotesService } from "./use-footnote-service";

const StyledSup = styled.sup`
  font: inherit;
  vertical-align: initial;
  font-feature-settings: "sups";
  line-height: 0;
  white-space: nowrap;
  /* This component ends up being rendered in a Nemo AEM site (https://audi.de/) which
  introduces global styles' breaking changes: https://www.audi.de/bin/nemo.static.20210217130452/cms4i-nemo/includes/core-ci/audi-core-ci-reset-and-basic.min.css
  The following workarounds are needed to make this component look identical on AEM and non AEM environments (Feature App Marketplace) */

  /* AEM workaround 1: font-size: max(1em, 16px); should be used but AEM changes the html font-size to font-size: 62.5%; */
  font-size: max(1em, 16px);

  /* AEM workaround 2: position: initial; should not be required but AEM changes the default sup tag to use position: relative; top: -5px */
  position: initial;
`;

const StyledLink = styled.a`
  && {
    border-bottom: none;
    font: inherit;
  }
`;

interface FootnoteReferenceProps {
  footnoteId: string;
}

interface FootnoteReferenceV2Props {
  footnoteId: string;
  footnoteAriaLabel?: string;
}

export function FootnoteReference({ footnoteId }: FootnoteReferenceProps) {
  // use custom foonote service hook to receive footnotes from the footnote reference service
  const footnote = useFootnotesService(footnoteId);

  return (
    <StyledSup className="egt-utils-footnote">
      <StyledLink
        className="egt-utils-footnote__link"
        href={`#${footnote?.contextID || footnoteId}`}
        target="_self"
      >
        {footnote?.number === undefined ? "*" : footnote.number}
      </StyledLink>
    </StyledSup>
  );
}

export function FootnoteReferenceV2({
  footnoteId,
  footnoteAriaLabel,
}: FootnoteReferenceV2Props) {
  // use custom foonote service hook to receive footnotes from the footnote reference service
  const footnote = useFootnotesService(footnoteId);

  return (
    <sup className="audi-footnote-anchor__text" translate="no">
      <a
        aria-label={footnoteAriaLabel || ""}
        href={`#${footnote?.contextID || footnoteId}`}
        target="_self"
        className="audi-j-footnote-reference"
      >
        {footnote?.number === undefined ? "*" : footnote.number}
      </a>
    </sup>
  );
}
