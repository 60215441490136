import { ImageShape, LogoShape } from '../contentTypes';

export const mockedFalconContent = {
  __type: 'aem-headless',
  id: 'mockedid',
  path: 'mockedpath',
  status: 'DRAFT',
  created: '2024-01-22T16:28:10.333Z',
  modified: '2024-01-22T16:28:48.858Z',
  title: 'Test Content',
  description: '',
  model: {
    id: 'mockedid',
    path: 'mockedId',
    title: 'Mocked Content',
    metadata: {
      baseUrl: 'http://fake.url/dev',
      id: 'mockedid',
      serverSrc: 'fh/app.node.js',
      src: 'fh/app.js',
      version: 'dev',
    },
  },
  fields: {
    tiles: [
      {
        id: 'mockedid',
        path: 'mockedpath',
        status: 'DRAFT',
        created: '2024-01-22T16:26:02.704Z',
        modified: '2024-01-22T16:29:32.548Z',
        title: 'Tile 1',
        description: '',
        model: {
          id: 'mockedid',
          path: 'mockedpath',
          title: 'tile model',
          metadata: {
            baseUrl: 'http://fake.url/dev',
            id: 'mockedid',
            serverSrc: 'fh/app.node.js',
            src: 'fh/app.js',
            version: 'dev',
          },
        },
        fields: {
          theme: 'dark',
          linkType: 'noLink',
          media_newWindow: false,
          media_fadeEnabled: false,
          media_zoomEnabled: true,
          media_imageEnabled: true,
          media_videoEnabled: false,
          media_image_srcSet: [
            {
              id: 'mockedid',
              path: 'mockedpath',
              status: 'DRAFT',
              created: '2024-01-22T16:08:41.287Z',
              modified: '2024-01-22T16:18:17.990Z',
              title: 'Tile 1 srcset',
              description: '',
              model: {
                id: 'mockedid',
                path: 'mockedpath',
                title: 'srcset model',
                metadata: {
                  baseUrl: 'http://fake.url/dev',
                  id: 'mockedid',
                  serverSrc: 'fh/app.node.js',
                  src: 'fh/app.js',
                  version: 'dev',
                },
              },
              fields: {
                src: '',
                srcAsset: {
                  path: 'https://www.audiusa.com/content/dam/nemo/us/Homepage/1277x718_M04.1_exterior_3-4_front_14_final_RGB-min.jpg?output-format=webp&downsize=1277px:*',
                },
                bp: 'xs',
              },
            },
          ],
          media_video_srcs_xs_src: '',
          media_video_srcs_xs_srcAsset: { path: '' },
          media_video_srcs_xs_poster: '',
          media_video_srcs_xs_posterAsset: { path: '' },
          media_video_srcs_s_src: '',
          media_video_srcs_s_srcAsset: { path: '' },
          media_video_srcs_s_poster: '',
          media_video_srcs_s_posterAsset: { path: '' },
          media_video_srcs_m_src: '',
          media_video_srcs_m_srcAsset: { path: '' },
          media_video_srcs_m_poster: '',
          media_video_srcs_m_posterAsset: { path: '' },
          media_video_srcs_l_src: '',
          media_video_srcs_l_srcAsset: { path: '' },
          media_video_srcs_l_poster: '',
          media_video_srcs_l_posterAsset: { path: '' },
          media_video_srcs_xl_src: '',
          media_video_srcs_xl_srcAsset: { path: '' },
          media_video_srcs_xl_poster: '',
          media_video_srcs_xl_posterAsset: { path: '' },
          media_video_srcs_xxl_src: '',
          media_video_srcs_xxl_srcAsset: { path: '' },
          media_video_srcs_xxl_poster: '',
          media_video_srcs_xxl_posterAsset: { path: '' },
          media_video_autoplay: false,
          media_video_loop: false,
          heading_text: 'NoLink Tile without href',
          heading_variant: 'order1',
          heading_embolden: false,
          subHeading_text: 'Test Subtitle',
          subHeading_variant: 'order2',
          subHeading_embolden: false,
          subHeading_htmlTag: 'h4',
          CTA_newWindow: false,
          horizontalAlignment: 'center',
          horizontalAlignmentSmall: 'center',
          verticalAlignment: 'center',
          verticalAlignmentSmall: 'center',
        },
      },
      {
        id: 'mockedid',
        path: 'mockedpath',
        status: 'DRAFT',
        created: '2024-01-22T16:20:31.564Z',
        modified: '2024-01-22T16:25:32.339Z',
        title: 'Tile 2',
        description: '',
        model: {
          id: 'mockedid',
          path: 'mockedpath',
          title: 'Tile Model',
          metadata: {
            baseUrl: 'http://fake.url/dev',
            id: 'mockedid',
            serverSrc: 'fh/app.node.js',
            src: 'fh/app.js',
            version: 'dev',
          },
        },
        fields: {
          theme: 'dark',
          linkType: 'noLink',
          media_newWindow: false,
          media_fadeEnabled: false,
          media_zoomEnabled: true,
          media_imageEnabled: true,
          media_videoEnabled: false,
          media_image_srcSet: [
            {
              id: 'mockedid',
              path: 'mockedpath',
              status: 'DRAFT',
              created: '2024-01-22T16:18:52.529Z',
              modified: '2024-01-22T16:19:08.228Z',
              title: 'Tile 2 srcSet',
              description: '',
              model: {
                id: 'mockedid',
                path: 'mockedpath',
                title: 'srcset model',
                metadata: {
                  baseUrl: 'http://fake.url/dev',
                  id: 'mockedid',
                  serverSrc: 'fh/app.node.js',
                  src: 'fh/app.js',
                  version: 'dev',
                },
              },
              fields: {
                srcAsset: {
                  path: 'https://www.audiusa.com/dam/nemo/us/models/e-tron/e-tron-sportback/730x730-overview-images/730x730-Audi-etronsb-accessories-min.jpg?output-format=webp&downsize=730px:*',
                },
                bp: 'xs',
              },
            },
          ],
          media_video_autoplay: false,
          media_video_loop: false,
          media_video_srcs_xs_src: '',
          media_video_srcs_xs_srcAsset: { path: '' },
          media_video_srcs_xs_poster: '',
          media_video_srcs_xs_posterAsset: { path: '' },
          media_video_srcs_s_src: '',
          media_video_srcs_s_srcAsset: { path: '' },
          media_video_srcs_s_poster: '',
          media_video_srcs_s_posterAsset: { path: '' },
          media_video_srcs_m_src: '',
          media_video_srcs_m_srcAsset: { path: '' },
          media_video_srcs_m_poster: '',
          media_video_srcs_m_posterAsset: { path: '' },
          media_video_srcs_l_src: '',
          media_video_srcs_l_srcAsset: { path: '' },
          media_video_srcs_l_poster: '',
          media_video_srcs_l_posterAsset: { path: '' },
          media_video_srcs_xl_src: '',
          media_video_srcs_xl_srcAsset: { path: '' },
          media_video_srcs_xl_poster: '',
          media_video_srcs_xl_posterAsset: { path: '' },
          media_video_srcs_xxl_src: '',
          media_video_srcs_xxl_srcAsset: { path: '' },
          media_video_srcs_xxl_poster: '',
          media_video_srcs_xxl_posterAsset: { path: '' },
          heading_text: 'NoLink Tile without href',
          heading_variant: 'order1',
          heading_embolden: false,
          subHeading_text: 'Test Subtitle',
          subHeading_variant: 'order2',
          subHeading_embolden: false,
          subHeading_htmlTag: 'h4',
          CTA_href: 'fakelink',
          CTA_variant: 'secondary',
          CTA_newWindow: false,
          horizontalAlignment: 'center',
          horizontalAlignmentSmall: 'center',
          verticalAlignment: 'center',
          verticalAlignmentSmall: 'center',
        },
      },
    ],
    layout_name: 'Basic - 2x1',
    layout_size: 'standard',
  },
};

export const mockedMappedContent = {
  layout: { name: 'Basic - 2x1', size: 'standard' },
  tiles: [
    {
      CTA: { href: '', variant: 'secondary', text: '', newWindow: false },
      heading: {
        text: 'NoLink Tile without href',
        variant: 'order1',
        htmlTag: 'h1',
        embolden: false,
      },
      horizontalAlignment: 'center',
      horizontalAlignmentSmall: 'center',
      icon: '',
      linkType: 'noLink',
      logo: {
        src: '',
        alt: '',
        heights: {
          xs: '',
          s: '',
          m: '',
          l: '',
          xl: '',
          xxl: '',
        },
      },
      materialDisclaimer: {
        xs: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
        s: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
        m: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
        l: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
        xl: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
        xxl: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
      },
      media: {
        newWindow: false,
        fadeEnabled: false,
        fade: {
          color: '',
          opacity: '',
          height: '',
          direction: 'bottom',
        },
        imageEnabled: true,
        videoEnabled: false,
        zoomEnabled: true,
        image: {
          alt: '',
          srcSet: [
            {
              src: 'https://www.audiusa.com/content/dam/nemo/us/Homepage/1277x718_M04.1_exterior_3-4_front_14_final_RGB-min.jpg?output-format=webp&downsize=1277px:*',
              bp: 'xs',
              srcAsset: '',
            },
          ],
        },
        video: {
          autoplay: false,
          loop: false,
          srcs: {
            xs: {
              poster: '',
              src: '',
            },
            s: {
              poster: '',
              src: '',
            },
            m: {
              poster: '',
              src: '',
            },
            l: {
              poster: '',
              src: '',
            },
            xl: {
              poster: '',
              src: '',
            },
            xxl: {
              poster: '',
              src: '',
            },
          },
        },
      },
      subHeading: { text: 'Test Subtitle', variant: 'order2', embolden: false, htmlTag: 'h4' },
      theme: 'dark',
      verticalAlignment: 'center',
      verticalAlignmentSmall: 'center',
    },
    {
      CTA: { href: 'fakelink', variant: 'secondary', text: '', newWindow: false },
      heading: {
        text: 'NoLink Tile without href',
        variant: 'order1',
        htmlTag: 'h1',
        embolden: false,
      },
      horizontalAlignment: 'center',
      horizontalAlignmentSmall: 'center',
      icon: '',
      linkType: 'noLink',
      logo: {
        src: '',
        alt: '',
        heights: {
          xs: '',
          s: '',
          m: '',
          l: '',
          xl: '',
          xxl: '',
        },
      },
      materialDisclaimer: {
        xs: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
        s: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
        m: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
        l: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
        xl: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
        xxl: {
          disclaimer: '',
          position: 'bottomRight',
          theme: 'dark',
        },
      },
      media: {
        newWindow: false,
        fadeEnabled: false,
        fade: {
          color: '',
          opacity: '',
          height: '',
          direction: 'bottom',
        },
        imageEnabled: true,
        videoEnabled: false,
        zoomEnabled: true,
        image: {
          alt: '',
          srcSet: [
            {
              src: 'https://www.audiusa.com/dam/nemo/us/models/e-tron/e-tron-sportback/730x730-overview-images/730x730-Audi-etronsb-accessories-min.jpg?output-format=webp&downsize=730px:*',
              bp: 'xs',
              srcAsset: '',
            },
          ],
        },
        video: {
          autoplay: false,
          loop: false,
          srcs: {
            xs: {
              poster: '',
              src: '',
            },
            s: {
              poster: '',
              src: '',
            },
            m: {
              poster: '',
              src: '',
            },
            l: {
              poster: '',
              src: '',
            },
            xl: {
              poster: '',
              src: '',
            },
            xxl: {
              poster: '',
              src: '',
            },
          },
        },
      },
      subHeading: { text: 'Test Subtitle', variant: 'order2', embolden: false, htmlTag: 'h4' },
      theme: 'dark',
      verticalAlignment: 'center',
      verticalAlignmentSmall: 'center',
    },
  ],
};

export const emptyLogo = {
  alt: '',
  src: '',
  srcAsset: '',
  heights: {
    xs: '18',
    s: '18',
    m: '24',
    l: '36',
    xl: '48',
    xxl: '60',
  },
} as LogoShape;

export const emptyImage = {
  alt: '',
  srcSet: [
    {
      bp: 'xs',
      src: '',
      srcAsset: '',
    },
  ],
} as ImageShape;

export const emptyVideo = {
  autoplay: true,
  loop: true,
  srcs: {
    xs: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    s: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    m: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    l: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    xl: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
    xxl: {
      src: '',
      srcAsset: '',
      poster: '',
      posterAsset: '',
    },
  },
};
